import React, { useEffect, useState } from "react"
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  Button,
  Modal,
} from "@material-ui/core"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Check from "../../static/svg/check.svg"
import LoadingModal from "../components/loadingModal/loadingModal"
import { Helmet } from "react-helmet"
import Popup from "../components/popup"

const useStyle = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  formBox: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    borderRadius: 20,
    border: "10px solid #EFEFF2",
  },
  formWrapper: {
    width: "44.375vw",
    margin: "0.78125vw 0",
    padding: "2.65vw",
    "@media(min-width: 1280px)": {
      width: "568px",
      margin: "10px 0",
      padding: "34px",
    },
    "@media(max-width: 1025px)": {
      width: "68.1vw",
      margin: "0.976vw 0",
      padding: "3.32vw",
    },
    "@media(max-width: 767px)": {
      width: "86.47vw",
      margin: "2.415vw 0",
      padding: 0,
    },
  },

  formTitle: {
    fontWeight: 600,
    lineHeight: 1,

    fontSize: "3.125vw",
    "@media(min-width: 1280px)": {
      fontSize: 40,
    },
    "@media(max-width: 1025px)": {
      fontSize: "4.796vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "8.695vw",
    },

    "& font": {
      ...theme.typography.body2,
      fontWeight: "inherit",
      lineHeight: "inherit",
      fontSize: "inherit",
    },
  },

  twoInputWrapper: {
    "& > *": {
      width: "47.42%",
    },
  },

  oneInputWrapper: {
    "& > *": {
      width: "100%",
    },
  },

  elementMarginTop: {
    marginTop: "3.4375vw",
    "@media(min-width: 1280px)": {
      marginTop: "44px",
    },
    "@media(max-width: 1025px)": {
      marginTop: "4.296vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "8.69vw",
    },
  },

  boldTitle: {
    fontWeight: 600,
  },
  input: {
    width: "100%",

    background: "transparent",
    border: `1px solid #D6D5DF`,
    outline: "none",

    fontWeight: 400,
    lineHeight: 1,

    marginTop: "1.09vw",
    padding: "1.21vw 0.93vw",
    borderRadius: "0.46vw",
    fontSize: "1.09vw",
    "@media(min-width: 1280px)": {
      marginTop: 14,
      padding: "15.5px 12px",
      borderRadius: 6,
      fontSize: 14,
    },
    "@media(max-width: 1025px)": {
      marginTop: "1.67vw",
      padding: "1.85vw 1.43vw",
      borderRadius: "0.71vw",
      fontSize: "1.67vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "3.38vw",
      padding: "3.74vw 2.89vw",
      borderRadius: "1.44vw",
      fontSize: "3.38vw",
    },

    "&:focus": {
      border: `1px solid ${theme.palette.color.accentSecondary}`,
    },
  },
  inputTitle: {
    display: "flex",
    fontWeight: 600,
    lineHeight: 1.21,

    fontSize: "1.328vw",
    "@media(min-width: 1280px)": {
      fontSize: "17px",
    },
    "@media(max-width: 1025px)": {
      fontSize: "1.66vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "4.1vw",
    },
  },

  agreementButton: {
    padding: 0,
    display: "flex",
    justifyContent: "flex-start",

    marginBottom: "0.93vw",
    "@media(min-width: 1280px)": {
      marginBottom: "12px",
    },
    "@media(max-width: 1025px)": {
      marginBottom: "1.43vw",
    },
    "@media(max-width: 767px)": {
      marginBottom: "2.89vw",
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },

  check: {
    position: "relative",
    background: theme.palette.background.main,
    flexShrink: 0,
    padding: 0,

    width: "1.25vw",
    height: "1.25vw",
    borderRadius: "0.46vw",
    marginRight: "0.62vw",
    boxShadow: `inset 0 0 0 0.07vw ${theme.palette.background.accentSecondary}`,
    "@media(min-width: 1280px)": {
      width: "16px",
      height: "16px",
      borderRadius: "6px",
      marginRight: "8px",
      boxShadow: `inset 0 0 0 1px ${theme.palette.background.accentSecondary}`,
    },
    "@media(max-width: 1025px)": {
      width: "1.91vw",
      height: "1.91vw",
      borderRadius: "0.71vw",
      marginRight: "0.95vw",
      boxShadow: `inset 0 0 0 0.11vw ${theme.palette.background.accentSecondary}`,
    },
    "@media(max-width: 767px)": {
      width: "3.86vw",
      height: "3.86vw",
      borderRadius: "1.44vw",
      marginRight: "1.93vw",
      boxShadow: `inset 0 0 0 0.24vw ${theme.palette.background.accentSecondary}`,
    },
  },
  active: {
    boxShadow: "none",
    background: theme.palette.background.accent,

    padding: "0.23vw",
    "@media(min-width: 1280px)": {
      padding: "3px",
    },
    "@media(max-width: 1025px)": {
      padding: "0.35vw",
    },
    "@media(max-width: 767px)": {
      padding: "0.72vw",
    },
  },
  agreementText: {
    lineHeight: 1.21,
    fontWeight: 400,

    fontSize: "1.094vw",
    "@media(min-width: 1280px)": {
      fontSize: 14,
    },
    "@media(max-width: 1025px)": {
      fontSize: "1.67vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "3.38vw",
    },
    "& font": {
      ...theme.typography.body2,
      fontWeight: "inherit",
      lineHeight: "inherit",
      fontSize: "inherit",
    },
  },
  button_send: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: "100%",
    background: theme.palette.background.accent,

    marginTop: "1.56vw",
    borderRadius: "0.93vw",
    height: "3.12vw",

    transition: "transform .1s cubic-bezier(.06,.66,.54,.93), opacity .1s cubic-bezier(.06,.66,.54,.93)",

    "&:hover": {
      opacity: .95,
    },

    "&:active": {
      transform: "scale(0.96)"
    },

    "@media(min-width: 1280px)": {
      marginTop: 20,
      borderRadius: 12,
      height: 40,
    },
    "@media(max-width: 1025px)": {
      marginTop: "2.39vw",
      borderRadius: "1.43vw",
      height: "4.79vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "4.83vw",
      borderRadius: "2.89vw",
      height: "9.66vw",
    },
  },
  button_send__text: {
    fontWeight: 700,
    lineHeight: 1.21,
    color: theme.palette.color.mainContrast,

    fontSize: "1.09vw",
    "@media(min-width: 1280px)": {
      fontSize: 14,
    },
    "@media(max-width: 1025px)": {
      fontSize: "1.67vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "3.38vw",
    },
  },
  bannerWrapper: {
    display: "flex",
    flexDirection: "row",
    position: "static",
    background:
      "radial-gradient(75.87% 70.04% at 36.14% 78.21%, #291AD5 0%, rgba(41, 26, 213, 0) 100%), radial-gradient(80.89% 46.22% at 67.44% 15.37%, #661DE1 0%, rgba(102, 29, 225, 0) 100%), linear-gradient(0deg, #EFEFF2, #EFEFF2)",
    width: "100%",
    borderRadius: "20px",

    padding: "5.664vw 3.9px",
    height: "46.875vw",
    margin: "3.125vw 0vw",

    "@media(min-width: 1280px)": {
      padding: "72.5px 50px",
      height: "600px",
      margin: "40px 0px",
    },
    "@media(max-width: 1025px)": {
      padding: "13.36vw 5.99vw",
      height: "71.94vw",
      margin: "4.79vw 0vw",
    },
    "@media(max-width: 767px)": {
      flexDirection: "column",
      padding: "9.66vw 7.24vw",
      height: "219.8vw",
      margin: "6.76vw 0",
    },
  },
  bannerTitle: {
    color: theme.palette.color.mainContrast,
    fontWeight: 900,
    lineHeight: "100.02%",

    "& font": {
      fontWeight: "inherit",
      lineHeight: "inherit",
      fontSize: "inherit",
      fontStyle: "italic",
    },

    fontSize: "5.9375vw",
    width: "40.39vw",

    "@media(min-width: 1280px)": {
      fontSize: "76px",
      width: "517px",
    },
    "@media(max-width: 1025px)": {
      fontSize: "5.995vw",
      width: "35.25vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "12.07vw",
      width: "71.98vw",
    },
  },
  bannerDiscription: {
    color: theme.palette.color.mainContrast,
    fontStyle: "normal",
    fontWeight: 300,
    opacity: "0.75",
    lineHeight: "150%",

    width: "29.29vw",
    fontSize: "1.718vw",
    marginTop: "2.1875vw",

    "@media(min-width: 1280px)": {
      width: "375px",
      fontSize: "22px",
      marginTop: "28px",
    },
    "@media(max-width: 1025px)": {
      width: "44.96vw",
      fontSize: "2.637vw",
      marginTop: "3.357vw",
    },
    "@media(max-width: 767px)": {
      width: " 71.98vw",
      fontSize: "4.106vw",
      marginTop: "6.763vw",
    },
  },
  bannerTextBox: {
    display: "flex",
    flexDirection: "column",
  },
  bannerButton: {
    background: theme.palette.color.mainContrast,
    display: "flex",
    borderRadius: "12px",

    width: "17.5vw",
    height: "4.14vw",
    padding: "1.25vw 2.8125vw",
    marginTop: "6.25vw",

    "@media(min-width: 1280px)": {
      width: "224px",
      height: "53px",
      padding: "16px 36px",
      marginTop: "80px",
    },
    "@media(max-width: 1025px)": {
      width: "26.858vw",
      height: "6.35vw",
      padding: "1.918vw 4.316vw",
      marginTop: "9.59vw",
    },
    "@media(max-width: 767px)": {
      width: "54.106vw",
      height: "12.8vw",
      padding: "3.86vw 8.69vw",
      marginTop: "11.11vw",
    },

    "& p": {
      ...theme.typography.body2,
      fontWeight: 700,
      lineHeight: "21px",

      fontSize: "1.328vw",
      "@media(min-width: 1280px)": {
        fontSize: 17,
      },
      "@media(max-width: 1025px)": {
        fontSize: "2.038vw",
      },
      "@media(max-width: 767px)": {
        fontSize: "4.106vw",
        lineHeight: 1.51,
      },
    },
  },
  bannerButtonText: {
    color: theme.palette.color.accent,
  },
  bannerImage: {
    position: "relative",

    width: "69.375vw",
    height: "51.95vw",
    left: "-16.718vw",
    top: "-7.8125vw",
    "@media(min-width: 1280px)": {
      width: "888px",
      height: "665px",
      left: "-214px",
      top: "-100px",
    },
    "@media(max-width: 1025px)": {
      width: "81.894vw",
      height: "63.908vw",
      left: "-214px",
      top: "-100px",
    },
    "@media(max-width: 767px)": {
      width: "182.6vw",
      height: "142.512vw",
      top: "-5.21vw",
      left: "-67.14vw",
    },
  },
  modal__message_wrapper: {
    background: theme.palette.background.main,
    position: "absolute",
    touchAction: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: "27.96vw",
    padding: "2.34vw",
    borderRadius: "1.5625vw",
    "@media(min-width: 1280px)": {
      width: "358px",
      padding: "30px",
      borderRadius: "20px",
    },
    "@media(max-width: 767px)": {
      width: "86.47vw",
      padding: "7.24vw",
      borderRadius: "4.83vw",
    },
  },
  modal__message: {
    ...theme.typography.body2,
    fontWeight: 600,
    textAlign: "center",

    fontSize: "1.875vw",
    lineHeight: "1.875vw",
    "@media(min-width: 1280px)": {
      fontSize: 24,
      lineHeight: 1,
    },
    "@media(max-width: 767px)": {
      fontSize: "5.79vw",
      lineHeight: "5.79vw",
    },
  },
  popupTitle: {
    maxWidth: 600,

    fontSize: "17px",
    fontWeight: "bold",
    color: "black",

    marginTop: 20,

    "&:first-child": {
      marginTop: 0
    },

    "@media(max-width: 1280px)": {
      fontSize: "1.8vw",
    },

    "@media(max-width: 1025px)": {
      fontSize: "2.4vw",
    },

    "@media(max-width: 767px)": {
      fontSize: "3.5vw",
    },

  },

  popupText: {
    maxWidth: 600,

    fontSize: "15px",
    color: "#111",

    "@media(max-width: 1280px)": {
      fontSize: "1.4vw",
    },

    "@media(max-width: 1025px)": {
      fontSize: "1.8vw",
    },

    "@media(max-width: 767px)": {
      fontSize: "3vw",
    },
  },

  popupFields: {
    display: "grid",
    gridTemplateColumns: "240px 200px",
    marginBottom: 5,

    "@media(max-width: 767px)": {
      gridTemplateColumns: "1fr 1fr",
    },
  },

  popupContent: {
    maxWidth: 500,
  },
}))

const IndexPage = () => {
  const classes = useStyle()

  const mobile = useMediaQuery("(max-width: 767px)")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("+7")
  const [email, setEmail] = useState("")
  const [inn, setInn] = useState("")
  const [shopName, setShopName] = useState("")
  const [checked, setChecked] = useState(false)
  const [isLegalEntityChecked, setIsLegalEntityChecked] = useState(true)
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [popupFields, setPopupFields] = useState([]);

  const [loadingOpen, setLoadingOpen] = React.useState(false)

  const [stateModal, setStateModal] = React.useState({})
  function openAndClose(message, delay) {
    delay = delay ?? 5000
    if (stateModal.timeoutId) clearTimeout(stateModal.timeoutId)

    const timeoutId = setTimeout(setStateModal, delay, {
      show: false,
      timeoutId: false,
      message: null,
    })

    setStateModal({
      show: true,
      timeoutId,
      message,
    })
  }

  function send() {
    if (validateForm()) {
      setLoadingOpen(true)

      const request = {
        name,
        phone,
        email,
        inn,
        shopName,
        isLegalEntityChecked,
      }

      const apiURL = `${process.env.API_URL}/api/sellers/form`

      const headers = new Headers()
      headers.append("Content-Type", "application/json")
      const body = JSON.stringify(request)
      const init = {
        method: "POST",
        headers,
        body,
      }

      fetch(apiURL, init)
        .then(res => res.json())
        .then(res => {
          if (res.success) {
            setName("")
            setPhone("+7")
            setEmail("")
            setInn("")
            setShopName("")
            openAndClose("Заявка отправлена")

            if (res.add_card_URL) {
              setTimeout(() => {
                window.open(res.add_card_URL, "_blank");
              }, 400);
            }
          } else {
            openAndClose(res.error_message)
          }
        })
        .catch(err =>
          openAndClose(
            "Возникла непредвиденная ошибка, обратитесь в техподдержку"
          )
        )
        .finally(() => setLoadingOpen(false))
    }
  }

  const validateForm = () => {
    if (
      name !== "" &&
      phone !== "" &&
      (email.includes("@") && email.includes(".")) &&
      inn !== "" &&
      checked && 
      (isLegalEntityChecked || shopName !== "")
    )
      return true
  }

  const scrollToForm = () => {
    var formElement = document.getElementById("seller-form")
    formElement.scrollIntoView({ block: "center", behavior: "smooth" })
  }

  useEffect(() => {
    setPopupFields([
      {
        label: "Имя",
        value: name,
      },
      {
        label: "Телефон",
        value: phone,
      },
      {
        label: "Email",
        value: email,
      },
      {
        label: "ИНН",
        value: inn,
      },
      {
        label: isLegalEntityChecked ? null : "Название магазина",
        value: shopName,
      },
      {
        label: "Субъект правовых отношений",
        value: isLegalEntityChecked ? "Юридическое лицо" : "Физическое лицо",
      },
    ].filter(({ label }) => label !== null));

  }, [isConfirmPopupOpen]);

  return (
    <Layout overflowX>
      <Helmet>
        <script type="application/ld+json">
          {`{
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Seller Form | Krypton.ru",
    "description": "Начните продавать в Krypton. Инструменты для увеличения ваших продаж",
    "url": "https://www.krypton.ru/seller-form",
    "mainEntity": {
      "@type": "Organization",
      "name": "Krypton",
      "description": "Krypton - Современный маркетплейс, в котором вы найдете оригинальную технику, смартфоны, ноутбуки, гаджеты и многое другое по низким ценам",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Санкт-Петербург",
        "addressCountry": "Россия"
      }
    }
  }`}
        </script>
      </Helmet>
      <Seo title="Seller Form" />
      <div className={classes.root}>
        <div className={classes.bannerWrapper}>
          <div className={classes.bannerTextBox}>
            <Typography className={classes.bannerTitle}>
              Начните продавать <br />в <font> Krypton</font>
            </Typography>
            <Typography className={classes.bannerDiscription}>
              Инструменты для увеличения ваших продаж
            </Typography>
            <Button
              className={classes.bannerButton}
              onClick={e => scrollToForm(e)}
            >
              <Typography className={classes.bannerButtonText}>
                Стать продавцом
              </Typography>
            </Button>
          </div>
          <div className={classes.imageWrapper}>
            <img
              src="/image/seller-form-phones.png"
              alt="seller-form-phones"
              className={classes.bannerImage}
            />
          </div>
        </div>
        <div
          id="seller-form"
          className={classes.formBox}
          style={mobile ? { border: "unset" } : null}
        >
          <div className={classes.formWrapper}>
            <Typography className={classes.formTitle}>
              Давайте <br />
              расти <font>вместе</font>
            </Typography>

            <Grid
              container
              justify="space-between"
              className={
                classes.elementMarginTop +
                (mobile
                  ? " " + classes.oneInputWrapper
                  : " " + classes.twoInputWrapper)
              }
            >
              <div>
                <Typography className={classes.inputTitle}>Имя</Typography>
                <input
                  value={name}
                  onInput={e => {
                    setName(e.target.value.replace(/[^а-яА-Яa-zA-Z ]/g, ""))
                  }}
                  className={classes.input}
                />
              </div>

              <div style={mobile ? { marginTop: "8.69vw" } : null}>
                <Typography className={classes.inputTitle}>Телефон</Typography>
                <input
                  value={phone}
                  onInput={e => {
                    setPhone(e.target.value.replace(/[^\d+-]/g, ""))
                  }}
                  className={classes.input}
                  maxLength={12}
                />
              </div>
            </Grid>
            <div className={classes.elementMarginTop}>
              <Typography className={classes.inputTitle}>Email</Typography>
              <input
                value={email}
                onInput={e => {
                  setEmail(e.target.value.replace(" ", ""))
                }}
                className={classes.input}
              />
            </div>

            <div>
              <Typography
                className={[classes.inputTitle, classes.elementMarginTop]}
              >
                ИНН
              </Typography>
              <input
                value={inn}
                onInput={e => {
                  setInn(e.target.value.replace(/[^\d]/g, ""))
                }}
                className={classes.input}
                maxLength={isLegalEntityChecked ? 10 : 12}
              />
            </div>

            <Button
              key={"agreement"}
              onClick={() => {
                if (!isLegalEntityChecked) {
                  setInn(inn.slice(0, 10));
                }

                setIsLegalEntityChecked(!isLegalEntityChecked);
              }}
              className={[classes.agreementButton, classes.elementMarginTop]}
              aria-label={"agreement"}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                className={
                  classes.check +
                  " " +
                  (isLegalEntityChecked ? classes.active : "")
                }
              >
                {isLegalEntityChecked ? <Check /> : null}
              </Grid>
              <Typography align="left" className={classes.agreementText}>
                Зарегистрировать юридическое лицо
              </Typography>
            </Button>

            <Popup
              isOpen={isConfirmPopupOpen}
              handleClose={() => setIsConfirmPopupOpen(false)}
            >
              <div className={classes.popupContent}>
                <Typography className={classes.popupTitle} style={{ marginBottom: 20 }}>Регистрация магазина</Typography>

                {popupFields.map(({ label, value }) => (
                  <div className={classes.popupFields}>
                    <Typography className={classes.popupText} style={{ opacity: .8, paddingRight: 10, }}>{label}</Typography>
                    <Typography className={classes.popupText}>{value}</Typography>
                  </div>
                ))}

                {!isLegalEntityChecked && (
                  <Typography className={classes.popupText} style={{ marginTop: 40, marginBottom: 20 }}>После регистрации откроется форма привязки бансковской карты для выплаты средств от продажи ваших товаров.</Typography>
                )}
                
                <button
                  onClick={send}
                  className={classes.button_send}
                  style={validateForm() ? null : { background: "gray" }}
                >
                  <Typography className={classes.button_send__text}>Отправить заявку</Typography>
                </button>

              </div>
            </Popup>

            {!isLegalEntityChecked && (
              <>
                <div className={classes.elementMarginTop}>
                  <Typography style={{ fontSize: "0.9rem" }}>При отправке заявки физического лица мы автоматически регистрируем магазин в эквайринге банка, для чего необходимо заполнить название магазина, а также привязать карту для выплат средств от продажи ваших товаров.</Typography>
                  
                </div>
                <div className={classes.elementMarginTop}>
                  <Typography className={classes.inputTitle}>Название магазина</Typography>
                  <Typography style={{ fontSize: "0.8rem" }}>Это название будет указано в ваших товарах на сайте</Typography>

                  <input
                    value={shopName}
                    onInput={e => {
                      setShopName(e.target.value)
                    }}
                    className={classes.input}
                    maxLength={50}
                  />
                </div>
              </>
            )}

            <div
              className={
                !isLegalEntityChecked ? classes.elementMarginTop : null
              }
            >
              <Button
                key={"agreement"}
                onClick={e => {
                  setChecked(!checked)
                }}
                className={classes.agreementButton}
                aria-label={"agreement"}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={
                    classes.check + " " + (checked ? classes.active : "")
                  }
                >
                  {checked ? <Check /> : null}
                </Grid>
                <Typography align="left" className={classes.agreementText}>
                  Я ознакомлен с порядком{" "}
                  <Link
                    to={"/documents/data-processing-policy/"}
                    style={{ textDecoration: "none" }}
                  >
                    <font>обработки персональных данных</font>
                  </Link>
                </Typography>
              </Button>
              <button
                onClick={() => setIsConfirmPopupOpen(true)}
                className={classes.button_send}
                style={validateForm() ? null : { background: "gray" }}
              >
                <Typography className={classes.button_send__text}>
                  Далее
                </Typography>
              </button>
              <LoadingModal
                isModalOpen={loadingOpen}
                title="Уже создаем заявку..."
              />
              <Modal
                open={stateModal.show}
                onClose={() => setStateModal({})}
                className={classes.modal}
              >
                <div className={classes.modal__message_wrapper}>
                  <Typography className={classes.modal__message}>
                    {stateModal.message}
                  </Typography>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

/**
 * Страница seller-form
 * @module src/page/seller-form
 */
export default IndexPage
